.btnLogin {
  border: none;
  border-radius: 12px;
  display: flex;
  height: 52px;
  padding: 0px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  background: #560bad;
  color: #FFF;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 650;
  line-height: 133%; /* 31.92px */
  margin-top: 20px;
  cursor: pointer;
}

.box-column-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.box-row-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btnTitleLogin {
  color: var(--Base-Black, #000);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Inter;
  font-size: 40px;
  font-style: normal;
  font-weight: 550;
  line-height: 20px; /* 50% */
  margin: 20px 0;
}

.wrapLogin {
  display: flex;
  width: 100%;
  padding: 50px;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-9, 24px);
  border-radius: var(--Boder-Radius-R1, 8px);
  background: var(--Other-White, #FFF);
  /* Shadow/lg */
  box-shadow: 0px 8px 10px 0px rgba(14, 20, 38, 0.06);
  max-width: 608px;
}

.wrapLogin > form{
  width: 100%;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20%;
}
.titleLogin {
  color: var(--Base-Black, #000);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 550;
  line-height: 25px; /* 50% */
}