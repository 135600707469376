@import '../../assets//theme.css';

html {
  scroll-behavior: smooth;
} 

.clickable {
  cursor: pointer;
}

.hidden {
  display: none;
}

code {
	color: var(--p-color-text-critical);
}


pre {
  white-space: pre-wrap;
  border: 1px solid #ccc;
  padding: 5px;
}

/* Responsive layout - hide layout in mobile */
@media (max-width: 800px) {
  .hide_mobile {
    display: none;
  }
}


.text_note {
  white-space: wrap;
  text-align: justify;
}

.service_thumbnail_inner {
  min-width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.service_thumbnail-avatar {
  width: 115px;
  height: 115px;
  cursor: pointer;
  position: relative;
}

.service_thumbnail-avatar img {
  min-width: 100%;
  height: 115px;
  width: 100%;
  border: 1px solid dashed;
}

.change-token {
  display: inline-flex;
  margin-top: 24px;
}

.news_thumbnail_inner {
  max-width: 935px;
  margin: auto;
  /* padding: 15px; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.news_thumbnail-avatar {
  width: 100%;
  height: 115px;
  cursor: pointer;
  position: relative;
}

.news_thumbnail-avatar img {
  min-width: 100%;
  height: 115px;
  width: 100%;
  border: 1px solid dashed;
}

.profile-description {
  font-size: 12px;
  line-height: 16px;
}

.quick_upload_outer {
	position: relative;
	height: 100%;
	display: flex;
	width: 100%;
	// background-color: #b4b4b431;
	min-height: 50px;
	align-items: center;
	justify-content: center;
  overflow: hidden;
  border: 2px dashed #ccc;

  .loaded {
    background: rgb(131,58,180);
    background: linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 50%, rgba(252,176,69,1) 100%);
    position: absolute;
    height: 100%;
    width: 0;
    left: 0;
    top: 0;
    opacity: .5;
  }

  .imageAfterUploadPlaceholder {
    width: 100%;
    height: auto;
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }
  .children_wrap {
    position: relative;
    z-index: 4;
  }

  .textPlaceholder {
    color: #fff;
    font-size: 12px;
    background-color: #4d4c4c91;
  }
}

.quick_upload_wrap {
  display: flex;
  opacity: 0;
  flex-flow: column nowrap;
  align-items: center;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 101;
  background-color: #0000003b;
  cursor: pointer;
  transition: all 0.25s ease 0s;
  justify-content: center;
}

.quick_upload_wrap:hover {
  opacity: 1;
}

.quick_upload {
  display: inline-flex;
  align-items: center;
  padding: 10px;
  background-color: #00000070;
  color: #fff;
  border-radius: 30px;
  text-decoration: none;
}

.quick_upload:hover {
  background-color: #00000090;
}

/* Responsive layout - makes a one column layout instead of a two-column layout */
@media (max-width: 800px) {
  .profile-description {
    text-align: center;
  }

  .profile_inner {
    flex-direction: column;
  }

  .Polaris-Page {
    padding: 10px;
  }
}


.paginationtable_wrap {
  padding: 10px;
  margin-bottom: 15px;

  .paginationtable_item {
    cursor: pointer;
    padding: 0 10px;
  }

  .paginationtable_item.disabled {
    cursor: default;
  }

  .paginationtable_item.active {
    cursor: default;
    border-radius: 6px;
    border: 1px solid #ccc;
    padding: 0 10px;
  }
}



.ck-editor__editable {
  min-height: 1000px;
}

.small-icon {
	font-size: 12px;
	padding: 0;
	height: auto;
	display: inline-block;
	vertical-align: middle;
}

.small-icon svg {
	vertical-align: middle;
	width: 15px;
	fill: #646464;
}

.small-icon span {
	display: inline-block;
  color: #646464
}

.post_title {
  font-weight: bold;
  color: rgb(6, 136, 49)
}
.post_excerpt {
  color:  #999
}



/**!SECTION
* Jamviet.com fixed topbar menu
*/

.Polaris-TopBar__SecondaryMenu {
	display: flex;
	align-content: center;
	justify-content: space-between;
}

.Polaris-Filters__Container {
  border: none;
}

/* Timeline Container */
.timeline {
  background: var(--primary-color);
  margin: 20px auto;
  position: relative;

  /* Outer Layer with the timeline border */
  .outer {
    border-left: 3px dotted #ccc;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .card {
    position: relative;
    margin: 0 0 25px 20px;
    width: 100%;
  }

  .timeline_handler {
    position: absolute;
    width: 25px;
    height: 25px;
    left: -34px;
    z-index: 98;
    background-color: #d5d5d5;
    border-radius: 50%;
    top: -2px;
  }

  .timeline_handler::after {
    content: "";
    position: absolute;
    background-color: rgb(62, 66, 66);
    width: 5px;
    height: 5px;
    border-radius: 999px;
    left: 9px;
    border: 3px solid rgb(40, 42, 43);
    top: 9px;
  }


  .title {
    color: orangered;
    position: relative;
  }
  .card .time .author {
    font-size: 12px;
    font-weight: bold;
    color: var(--p-color-text-interactive);
    margin: 5px 0;
  }
  .card .time {
    font-size: 12px;
    color: #959595;
  }
  .card .time .hour {
    font-size: 16px;
    color: #000;
    margin-right: 10px;
  }

  .step-item {
    margin: 2.5rem 0;
    font-size: 17px;
    left: -20px;
    position: relative;
    padding: 5px 0;
    background-color: #f2f2f2;
    letter-spacing: -0.9px;
    color: var(--p-color-text-subdued);
  }
  

}



// .todo-content{
// display: flex;
// }
// .todo-inner {
//   flex: 1 1;
// }

// .todo-inner-flex {
// 	display: flex;
// 	align-items: center;
// 	justify-content: space-between;
// 	padding: 10px 15px;
// }

// .SJpYG > span {
//   display: flex;
// }


// .todo-checkbox-btn {
//   -webkit-appearance: none;
//   appearance: none;
//   background: none;
//   border: none;
//   color: inherit;
//   cursor: pointer;
//   display: flex;
//   font-size: inherit;
//   line-height: inherit;
//   margin: 0;
//   padding: 0;
//   position: relative;
// }


// .todo-description {
//   -webkit-appearance: none;
//   appearance: none;
//   background: none;
//   border: none;
//   color: inherit;
//   cursor: pointer;
//   display: flex;
//   font-size: inherit;
//   line-height: inherit;
//   margin: 0;
//   margin: var(--p-space-1) var(--p-space-2);
//   padding: 0;
//   padding: var(--p-space-1) var(--p-space-1);
//   position: relative;
//   text-align: left;
//   width: 64%;
// }

// /**!SECTION
// * Jamviet add to dashboard screen ...
// */
// .todoListAtHomePage {
//   .todoHeading {
//     padding: var(--p-space-4);
//   }
//   .todoListHeading {
//     padding: var(--p-space-3) var(--p-space-4);
//     background-color: var(--p-action-secondary-hovered);
//   }
//   ul {
//     list-style: none;
//     margin: 0;
//     padding: 0;
//     li {
//       display: flex;
//       align-content: center;
//       justify-content: space-between;
//       margin-bottom: 5px;
//       padding: var(--p-space-2);

//       .todoHasIconCheck {
//           width: 10%;
//           text-align: center;
//           svg, img {
//             width: 25px;
//           }
//       }
//       .todoContent {
//         width: 90%;
//         padding-top: 2px;

//         .todoLine {
//           margin-bottom: 5px;
//         }
//       }
//     }
//     li:hover {
//       background-color: #eee;
//     }
//     li:last-child {
//       margin-bottom: 0
//     }
//   }
// }


.timeline-wraper {
  position: relative;
  padding: 45px 0;

  .timeline-label {
    width: 50px;
    flex-shrink: 0;
    position: relative;
    color: #333333;
  }

  .timeline-item {
    display: flex;
    align-items: flex-start;
    position: relative;
    margin-bottom: 1.7rem;
  }
  
  .timeline-badge {
    flex-shrink: 0;
    background-color: var(--p-color-bg-app);
    width: 1rem;
    height: 1rem;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    position: relative;
    margin-top: 3px;
    margin-left: -0.4rem;
    padding: 3px !important;
    border: 3px solid var(--p-color-bg-interactive) !important;
  }

  .badge_0 { border-color: var(--p-color-icon-info) !important }
  .badge_4 { border-color: var(--p-color-bg-critical-strong) !important }
  .badge_2 { border-color: var(--p-color-bg-info-strong) !important }
  .badge_3 { border-color: var(--p-color-bg-magic-strong) !important }
  .badge_1 { border-color: var(--p-color-icon-primary) !important }
  .badge_5 { border-color: var(--p-color-text-magic) !important }

  .timeline-content {
    margin-left: 0.5rem;
    flex-grow: 1;
  }

}

.timeline-wraper::before {
	content: "";
	position: absolute;
	left: 51px;
	width: 3px;
	top: 0;
	bottom: 0;
	background-color: var(--p-border-neutral-subdued);
}


.home_analytics {
  padding: 15px;
  background: url('../images/ecommerce-bg.png') no-repeat center right #FFF;
  background-position: right bottom;
  background-size: 230px;
  border-radius: 8px;

  .home_analytics_body {
    display: flex;
    padding: 1.2rem 0;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    
    .home_analytics_badget {
        border: 2px dashed #ccc;
        border-radius: 6px;
        padding: 5px 10px;
        margin-right: 15px;
        min-width: 111px;


        .num {
          font-size: 20px;
          font-weight: bold;
          color: var(--p-color-bg-critical-strong-active);
        }
        .des {
          font-size: 12px;
          color: #404040
        }
    }
  }
}


.has_vip {
  position: relative;
}

.has_vip::after {
	content: "";
	position: absolute;
  background: url('../images/StarFilledMinor.svg') no-repeat left center transparent;
	background-size: 8px 8px;
	width: 8px;
	height: 8px;
	top: 0;
	left: -13px;
}


#filterWrapper {
  padding-left: 10px;

  .FilterPill {
    padding: 2px 12px;
    border: 1px solid #ccc;
    border-radius: 20px;
    font-size: 12px;
    cursor: pointer;
    margin-right: 5px;
  }
}



.fullScreenPage {
	position: absolute;
	z-index: 398;
	left: 0;
	top: 0;
	width: 100vw;
	min-height: 100vh;
	max-height: 100vh;
	background-color: #FFF;

  .fullScreenHead {
    position: fixed;
    z-index: 399;
    width: 100vw;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.9);

    .Polaris-FullscreenBar {
      background-color: unset !important;
    }
  }
  .fullscreenBody {
    padding: 0;
    margin-bottom: 4rem;
    margin-top: 58px;
    height: 100%;
    overflow-x: hidden;

    .Polaris-Box {
      border: none !important
    }
  }

  .sale_order_note > div {
    width: 300px;
    white-space: normal;
  }

// .Polaris-IndexTable__TableCell--first,
//   .Polaris-IndexTable__TableHeading--first {
//     visibility: collapse;
//     display: none;
//   }
  
}


.table_app_cellMinWidth {
  min-width: 150px;
}



#customer_detail {
  padding: var(--p-space-4);
}


.SMS_wrap {
  padding: 5px;

  .SMS_Element {
    padding: 5px;

    .SMS_title {
      font-size: 10px;
      color: #6a6a6a;
    }
    .SMS_content {
      position: relative;
      border: 1px solid #ccc;
      border-radius: 6px;
      padding: 6px;
      background-color: #37D85F;
      color: #fff;
    }
    .SMS_content::after {
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid #37D85F;
        content: "";
        position: absolute;
        left: 5px;
        top: -5px;
    }

  }
}


.notification_wrap {

  .notification_heading {
      font-size: 14px;
      font-weight: bold;
      text-transform: uppercase;
      color: #666;
      margin: 10px;
  }

  .notification_element {
    padding: 10px;
    display: flex;
    align-items: flex-start;
    cursor: pointer;
    gap: 10px;
    text-decoration: none;

    .notification_body {
      line-height: 1.4;

      .l2 {
        font-size: 12px;
        color: #5f5f5f;
      }
    }
  }
  .notification_element:hover {
    background-color: #EEE;
  }
}


/*
  Vote star for customer ...
*/

.rate_wrap {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin: 10px 0;
	font-size: 12px;
}

.rate {
  padding: 0 10px;
  position: relative;
  transition: color 0.2s ease;
}
.rate:not(:checked) > input {
  position:absolute;
  top:-1px;
  visibility: hidden;
  transition: color 0.2s ease;
}
.rate:not(:checked) > label {
  float:right;
  width:1em;
  overflow:hidden;
  white-space:nowrap;
  cursor:pointer;
  font-size:22px;
  color:#ccc;
  transition: color 0.2s ease;
}
.rate:not(:checked) > label:before {
  content: '★ ';
}
.rate > input:checked ~ label {
  color: #ffd746;    
}
.rate:not(:checked) > label:hover,
.rate:not(:checked) > label:hover ~ label {
  color: #deb217;  
}
.rate > input:checked + label:hover,
.rate > input:checked + label:hover ~ label,
.rate > input:checked ~ label:hover,
.rate > input:checked ~ label:hover ~ label,
.rate > label:hover ~ input:checked ~ label {
  color: #c59b08;
}

.star_wrap {
	display: inline-block;
	width: 75px;
	position: relative;
	height: 20px;
	margin-right: 10px;
	vertical-align: middle;
}


.star {
  display: inline-block;
  width: 15px;
  height: 15px;
  background: url('../images/StarOutlineMinor.svg') no-repeat center center transparent;
}
.star.checked {
  background: url('../images/StarFilledMinor.svg') no-repeat center center transparent;
} 








.BorderGrid-row {
	display: block;
  width: 100%;

  .BorderGrid-cell {
    padding-top: 24px;
    padding-bottom: 24px;
  }
  .Progress {
    display: flex;
    height: 8px;
    background-color: var(--p-color-bg-app-selected);
    border-radius: 6px;
    outline: 1px solid transparent;
  }
  .progress_size_normal {}
  .progress_size_medium {height: 16px;}
  .progress_size_big {height: 32px;}

  .Progress-item + .Progress-item {
    margin-left: 2px;
  }
  .Progress-item {
    outline: 2px solid transparent;
    height: 100%;
    position: relative;
  }
  .Progress-item .m {
    display: none;
    position: absolute;
  }
  .Progress-item .m::after {
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid #CCC;
      content: "";
      position: absolute;
      bottom: -8px;
      left: 14px;
      z-index: 100;
  }
  .Progress-item.z > .m {
    display: block;
    position: absolute;
    z-index: 99;
    top: -32px;
    border-bottom: 1px solid #ccc;
    background-color: #fff;
    box-shadow: 0px 0px 3px 2px #ccc;
    padding: 3px 5px;
    min-width: 160px;
  }
  .list-style-none {
    list-style: none !important;
    padding: 0;
  }
  .d-inline {
    display: inline !important;
    margin-right: 5px;
  }
  .octicon {
    display: inline-block;
    overflow: visible !important;
    vertical-align: text-bottom;
    fill: currentColor;
  }
}


.list_user_loosing_space {
    display: flex;
    gap: 0;
  span {
    margin-right: -6px;
  }
}



.Polaris_table_tr_light_text {
  color: #fff;
  .Polaris-Button__Content {
    color: #fff
  }
  .Polaris-Button--plain:hover svg,
  svg, svg:hover {
    color: #fff !important;
    fill: currentColor;
  }
  button {
    color: #fff
  }
  button:hover {
    color: #fff
  }
}


.Polaris-IndexTable--statusSubdued {
  background-color: var(--p-color-avatar-style-four-background-experimental) !important;
  .Polaris-IndexTable__TableCell:first-child,
  .Polaris-IndexTable__TableCell:last-child {
    background-color: var(--p-color-avatar-style-four-background-experimental) !important;
  }

}

.Polaris-IndexTable__Table  {

  
  .draggable-table__drag {
      font-weight: lighter;
      text-transform: capitalize;    
      position: absolute;
      width: 100%;
      border: 1px solid #f1f1f1;
      z-index: 50;
      cursor: grabbing;    
      opacity: 0.9;
  }  

  
  tbody {
    tr.is-dragging {
      cursor: move;
    }    

    td:visited,
    td:active,
    td:target,
    td:focus-visible,
    td:focus-within,
    td:focus {
      border: none !important;
      outline: 1px solid rgba(255, 255, 255, 0);
      box-shadow: none;
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
    }
    td::-moz-focus-inner { border: 0; }
  }
}





#context_menu {
  visibility: hidden;
  opacity: 0;
  position: fixed;
  background: #fff;
  color: #555;
  font-family: sans-serif;
  font-size: 11px;
  -webkit-transition: opacity .1s ease-in-out;
  -moz-transition: opacity .1s ease-in-out;
  -ms-transition: opacity .1s ease-in-out;
  -o-transition: opacity .1s ease-in-out;
  transition: opacity .1s ease-in-out;
  -webkit-box-shadow: 2px 2px 2px 0px rgba(143, 144, 145, 1);
  -moz-box-shadow: 2px 2px 2px 0px rgba(143, 144, 145, 1);
  box-shadow: 2px 2px 2px 0px rgba(143, 144, 145, 1);
  padding: 0px;
  border: 1px solid #C6C6C6;
  z-index: 9999;
}

#context_menu a {
  display: block;
  color: #555;
  text-decoration: none;
  padding: 6px;
  width: 100%;
  position: relative;
}

#context_menu a span {
  color: #BCB1B3;
  float: right;
}

#context_menu .tool a:hover {
  color: #fff;
  background-color: #4b4b4b;
}

#context_menu hr {
  border: 1px solid #EBEBEB;
  border-bottom: 0;
}

#context_menu .color_bookmark {
	display: flex;
	padding: 5px;
	gap: 5px;
	justify-content: flex-start;
	align-content: center;
}

#context_menu .color_bookmark a {
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 1px solid transparent;
  position: relative;
}
#context_menu .color_bookmark a:hover {
  border: 1px solid black;
}
#context_menu .color_bookmark a.black {
  background-color: black;
}
#context_menu .color_bookmark a.red {
  background-color: red;
}
#context_menu .color_bookmark a.blue {
  background-color: blue;
}
#context_menu .color_bookmark a.green {
  background-color: green;
}
#context_menu .color_bookmark a.yellow {
  background-color: yellow;
}
#context_menu .color_bookmark a.aqua {
  background-color: aqua
}
#context_menu .color_bookmark a.none {
  background: url('../images/none_banned.png') no-repeat center center #fff;
  background-size: contain;
}


/*
https://codepen.io/oliviale/pen/qpPByV
Hexagon Badges
*/

@mixin margin-auto {
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
}


.user_achievement {
    // overflow: scroll;
    // white-space: nowrap;
}
.user_achievement_element {
  cursor: pointer;
  text-decoration: none;
}

.badge {
	position: relative;
	margin: 1.5em 3em;
	width: 3em;
	height: 4.2em;
	border-radius: 10px;
	display: inline-block;
	top: 0;
	transition: all 0.2s ease;
	&:before,
	&:after {
		position: absolute;
		width: inherit;
		height: inherit;
		border-radius: inherit;
		background: inherit;
		content: "";
		@include margin-auto;
	}
	&:before {
		transform: rotate(60deg);
	}
	&:after {
		transform: rotate(-60deg);
	}
	&:hover {
		top: -4px;
	}
	.circle {
      width: 30px;
      height: 30px;
      position: absolute;
      /* background: #fff; */
      z-index: 10;
      border-radius: 50%;
      top: -12px;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
	}

  .ribbon {
    position: absolute;
    border-radius: 4px;
    padding: 2px;
    width: 100px;
    z-index: 11;
    color: #fff;
    bottom: 5px;
    left: 50%;
    margin-left: -50px;
    text-align: center;
    font-size: 10px;
    line-height: 1.4;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.27);
    text-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
    text-transform: uppercase;
    background: linear-gradient(to bottom right, #555 0%, #333 100%);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

}

.yellow {
	background: linear-gradient(to bottom right, #ffeb3b 0%, #fbc02d 100%);
	color: #ffb300;
}

.orange {
	background: linear-gradient(to bottom right, #ffc107 0%, #f57c00 100%);
	color: #f68401;
}

.pink {
	background: linear-gradient(to bottom right, #F48FB1 0%, #d81b60 100%);
	color: #dc306f;
}

.red {
	background: linear-gradient(to bottom right, #f4511e 0%, #b71c1c 100%);
	color: #c62828;
}

.purple {
	background: linear-gradient(to bottom right, #ab47bc 0%, #4527a0 100%);
	color: #7127a8;
}

.teal {
	    background: linear-gradient(to bottom right, #4DB6AC 0%, #00796B 100%);
    color: #34a297;
}

.blue {
	    background: linear-gradient(to bottom right, #4FC3F7 0%, #2196F3 100%);
    color: #259af3;
}

.blue-dark {
	    background: linear-gradient(to bottom right, #1976D2 0%, #283593 100%);
    color: #1c68c5;
}

.green {
	background: linear-gradient(to bottom right, #cddc39 0%, #8bc34a 100%);
	color: #7cb342;
}

.green-dark {
	    background: linear-gradient(to bottom right, #4CAF50 0%, #1B5E20 100%);
    color: #00944a;
}

.silver {
	background: linear-gradient(to bottom right, #E0E0E0 0%, #BDBDBD 100%);
    color: #9e9e9e;
}

.gold {
	background: linear-gradient(to bottom right, #e6ce6a 0%, #b7892b 100%);
    color: #b7892b;
}







/**!SECTION
* Task list
*/


.task_list_wrap {
  height: auto;

  .task_list_template {
    display: flex;
    align-content: center;
    justify-content: flex-start;
    gap: 2px;
    margin-bottom: 10px;

      .task_mark {
        // background: url('./dot.svg') no-repeat center center transparent;
        // background-size: 10px 10px;
        width: 15px;
        height: 15px;
        position: relative;
        margin-right: 15px;
      }

      span::after {
          content: " ";
          position: relative;
          width: 15px;
          height: 15px;
          display: block;
          background-color: rgb(133, 133, 133);
          border-radius: 4px;
          top: 3px;
      }
      span.processing::after {
        background-color: red;
      }
      span.cancel::after {
        background-color: black;
      }
      span.done::after {
        background-color: green;
      }

      .task_content {
        
        .task_title {
          font-weight: bold;
          margin-bottom: 5px;
        }
        .task_assign {
          color: #757575;
          font-size: 12px;
        }
      }
  }
}

.Polaris-TopBar-Menu__Activator {
  background-color: #ffffff;
  color: #000000;
}
.Polaris-TopBar-Menu__Activator:hover {
  color: #ffffff;
  background-color: #757575;
}
.Polaris-TopBar-SearchField__Input {
  background-color: #ffffff;
}
.Polaris-TopBar__SecondaryMenu svg {
  fill: unset;
}
.Polaris-TopBar-Menu__Activator:hover svg {
  fill: var(--p-color-icon-inverse);
}

// custom color tab list order status
#draft {
  background-color: var(--p-color-bg-fill-caution-secondary);
  color: var(--p-color-text-caution);
}
#approved {
  background-color: var(--p-color-bg-fill-success-secondary);
  color: var(--p-color-text-success);
}
#transporting {
  background-color: var(--p-color-bg-fill-info-secondary);
  color: var(--p-color-text-info);
}
#completed {
  color: var(--p-color-text-success-on-bg-fill);
  background-color: var(--p-color-bg-fill-success);
}
#cancel {
  background-color: var(--p-color-bg-fill-critical-secondary);
  color: var(--p-color-text-critical);
}
#customer_cancel {
  background-color: var(--p-color-bg-fill-critical-secondary);
  color: var(--p-color-text-critical);
}
#refunded {
  background-color: var(--p-color-bg-fill-warning-secondary);
  color: var(--p-color-text-warning);
}
#returned {
  color: var(--p-color-text-critical-on-bg-fill);
  background-color: var(--p-color-bg-fill-critical);
}
#viewerBackdrop img {
  min-width: 350px !important;
}

.Polaris-DataTable {
  border-radius: unset !important;
}
/** END TASK LIST TEMPLATE */
