.box_image {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    flex-direction: column;
    padding: 1rem;
    min-height: 26.75rem;
    background: #fff;
    border-radius: 12px;
}

.box_list_image {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 1rem;
    margin-top: 1rem;

    .btn_upload {
        width: 6.25rem;
        height: 6.25rem;
        border-radius: 0.5rem;
        border: 1px dashed #8a8a8a;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
            margin: auto;
        }

        &:hover {
            cursor: pointer;
        }
    }
}

.img_product {
    width: 6.25rem;
    height: 6.25rem;
    border-radius: 0.5rem;
    position: relative;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 0.5rem;
    }

    button{
        border-radius: 50%;
        position: absolute;
        top: 2px;
        right: 3px;
    }

    .icon_crop {
        border-radius: 50%;
        position: absolute;
        bottom: 20px;
        left: 25px;
    }
}

.img_icon_upload {
    width: 6.25rem;
    height: 6.25rem;
    border-radius: 0.5rem;
    position: relative;
    justify-content: center;
    align-items: center;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 0.5rem;
    }

    button{
        border-radius: 50%;
        position: absolute;
        top: 2px;
        right: 3px;
        background-color: #000000;
    }

    .icon_crop {
        border-radius: 50%;
        position: absolute;
        bottom: 20px;
        left: 25px;
    }
}


.box_list_image_category {
    margin-top: 1rem;
    .btn_upload {
        width: 6.25rem;
        height: 6.25rem;
        border-radius: 0.5rem;
        border: 1px dashed #8a8a8a;
        display: flex;

        svg {
            margin: auto;
        }

        &:hover {
            cursor: pointer;
        }
    }
}

.btn_upload_banner {
    width: 100%;
    height: 6.25rem;
    border-radius: 0.5rem;
    border: 1px dashed #8a8a8a;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
        margin: auto;
    }

    &:hover {
        cursor: pointer;
    }
}

.img_banner_upload {
    width: 100%;
    height: 100%;
    border-radius: 0.5rem;
    position: relative;
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: 0.5rem;
    }

    button{
        border-radius: 50%;
        position: absolute;
        top: 2px;
        right: 3px;
    }

    .icon_crop {
        border-radius: 50%;
        position: absolute;
        bottom: 20px;
        left: 25px;
    }
}

#box_description{
    margin-top: 1rem;
}