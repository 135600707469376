/* Thay đổi màu của navbar */
.Polaris-TopBar {
  background-color: #000000;
}

.Polaris-InlineStack {
  flex-wrap: nowrap !important;
}

.Polaris-Navigation__Section {
  padding-bottom: 0;
}

.Polaris-Icon {
  margin: 0;
}

.Polaris-TextField__Spinner {
  display: none;
}

/* Thay đổi màu của title table */
table thead tr th,
.Polaris-IndexTable__BulkActionsWrapperVisible,
.Polaris-IndexTable__BulkActionsWrapperVisible .Polaris-CheckableButton__Label,
table thead tr th button {
  background-color: #000000 !important;
  color: #ffffff !important;
  font-weight: bold !important;
}

/* Thay đổi màu icons trong table */
table thead tr th svg {
  fill: #ffffff !important;
}