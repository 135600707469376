


#vertical-menu {}
#vertical-menu .nav-item{
    text-decoration: none;
    font-size: 1.4em;
    color: var(--p-text-heading-2xl-font-size);
}
#vertical-menu .nav-item.active{
    font-weight: bold;
}