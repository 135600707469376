

.help-wrap {
    font-size: 16px;
}

.help-wrap .help-header {
	background-color: green;
	border-bottom-left-radius: 20px;
	border-bottom-right-radius: 20px;
	margin-bottom: 2em;
}

.help-wrap .help-header h1{
        color: white;
        font-size: 2em;
        padding: 30px;
        line-height: 1.3;
}

.help-wrap .help-header img{
    border: none;
    width: 100%;
    height: auto;
}



#post_wrap {
    line-height: 1.4;
    font-size: 16px;
}
#post_wrap #post_excerpt {
    font-size: 16px;
}
#post_wrap #post_meta {
    font-size: 14px;
    color: #616161
}
#post_wrap #post_content {}




