
#login_page {
    margin-top: 1rem;
    max-width: 500px;
    width: 500px;
    min-height: 500px;
  }
  
  
    #profile_heading {
      padding: 1.5rem;
      padding-top: 2rem;
      background: url('./user_profile_header.jpg') no-repeat center center transparent;
      background-size: cover;
      background-color: var(--p-surface-dark);
      box-shadow: var(--p-card-shadow,
          0 0 0 1px rgba(63, 63, 68, 0.05),
          0 1px 3px 0 rgba(63, 63, 68, 0.15));
      outline: 0.1rem solid transparent;
      color: #fff;
      position: relative;
  
      
      .Polaris-Button__Text {
        color: #fff;
      }
  
  
      svg {
        fill: #fff;
      }
  
  
      .profile-avatar {
        width: 115px;
        height: 115px;
        margin-bottom: 15px;
        margin-right: 15px;
        cursor: pointer;
        position: relative;
        justify-content: center;
      }
      
      .profile-avatar img {
        border-radius: 50%;
        width: 100%;
        height: 100%;
      }
    
    
  
  
    }
  
    @media (max-width: 47.9975em) {
      .profile-avatar,
      .profile-description {
        width: 100% !important;
        text-align: center;
      }
    }
    
  
  
  